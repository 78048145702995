import React from "react"
import Layout from "components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "components/SEO"

const BlogPost = () => {
  return (
    <div>
      <Seo
        title="Life Cycle of a Darkling Beetle (Mealworm) | Mandatory Insects"
        description=" Darkling beetles (also known as Mealworm Beetles) are prolific
          breeders."
      />
      <Layout>
        <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
          <div className="lg:w-10/12 w-full">
            <h2 className="xl:w-8/12 lg:w-10/12 w-full font-bold text-gray-800 lg:text-4xl text-3xl lg:leading-10 leading-9 mt-2">
              Life Cycle of a Darkling Beetle
              <span className="block text-lime-600 xl:inline">(Mealworm)</span>
            </h2>

            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              Darkling beetles (also known as Mealworm Beetles) are prolific
              breeders. It is harder to stop them breeding than it is to get
              them to breed.
            </p>
            <p className="text-center">
              <StaticImage
                src="../../components/images/website/Blog/darkling-beetle-life-cycle.jpg"
                alt="Mealworms"
                width={200}
                placeholder="blurred"
              />
            </p>

            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              There are 4 stages in the life cycle of a mealworm:
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              <strong>Eggs:</strong> In a matter of days after mating (dependent
              on incubation temperature) the female will burrow into soft ground
              and lay between 70 and 100 eggs. The egg stage typically lasts
              7-14 days but low temperatures and humidity can lengthen or even
              double this incubation time.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              <strong>Larva:</strong> The Larva (known as mealworms) will eat
              various fruits and vegetables. During this stage a mealworm will
              shed its skin 10-14 times as it gets too big for its old skin.
              They are a tan colour but when it sheds its skin it is a white
              colour. Shedding is when the skin of a mealworm splits down the
              back and the mealworm slides out. On its last shedding it loses
              its skin and then curls up into its pupa form.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              <strong>Pupa:</strong> The pupa is the inactive non-feeding stage
              that the mealworms go through. The mealworm remains in its pupa
              stage for 6-30 days (dependent on incubation temperature). The
              pupa starts a creamy white and changes slowly to a brown colour
              during its pupation stage which includes a complete metamorphosis
              which transforms them into reproducing adult beetles.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              <strong>Adult:</strong> Two or three weeks after the mealworm has
              pupated, a mealworm beetle will emerge. These are known as
              Darkling beetles. The newly hatched Beetle will sit still as its
              wings unfold and dry. It will appear as a creamy colour and will
              slowly brown over a period of 2-7 days. Once the Beetles have
              turned black they will become sexually mature and begin to look
              for a mate.{" "}
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              Darkling beetles can live for quite some time however they slow
              down their reproduction after about 3 months.
            </p>

            <span className="text-lime-700">Mandatory Insects</span>
          </div>

          <div className="lg:mt-14 sm:mt-10 mt-12">
            <StaticImage
              className="lg:block w-full"
              src="../components/images/website/Headers/mealworm-about.jpg"
              alt="Mealworms"
              layout="fullWidth"
              placeholder="blurred"
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default BlogPost
